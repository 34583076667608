var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-section-alternating',{attrs:{"id":"alternating-features"}},[_c('base-section-heading-alternating',{staticClass:"px-2",attrs:{"title":"FirstVault Features"}},[_vm._v(" Secure your digital life using FirstVault: Securely store and access everything you need, and leave the security concerns to us. ")]),_c('v-container',{attrs:{"fluid":""}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-row',[_vm._l((_vm.features),function(feature,i){return [(
            i === 0 ||
            i === 1 ||
            i === 4 ||
            i === 5 ||
            i === 8 ||
            i === 9 ||
            i === 12 ||
            i === 13
          )?_c('v-col',{key:i,staticClass:"my-4 py-4",attrs:{"id":"color","cols":"12","sm":"6"}},[_c('base-avatar-card',_vm._b({attrs:{"align":"justify","horizontal":""}},'base-avatar-card',feature,false))],1):_c('v-col',{key:i,staticClass:"ma-0 pa-0",attrs:{"cols":"12","sm":"6"}},[_c('base-avatar-card',_vm._b({attrs:{"align":"justify","horizontal":""}},'base-avatar-card',feature,false))],1)]})],2):_c('v-row',[_vm._l((_vm.featuresMini),function(feature,j){return [(j === 0 || j === 2 || j === 4 || j === 6)?_c('v-col',{key:j,attrs:{"id":"color","cols":"12","sm":"12"}},[_c('base-avatar-card',_vm._b({attrs:{"align":"justify","horizontal":""}},'base-avatar-card',feature,false))],1):_c('v-col',{key:j,attrs:{"cols":"12","sm":"12"}},[_c('base-avatar-card',_vm._b({attrs:{"align":"justify","horizontal":""}},'base-avatar-card',feature,false))],1)]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }