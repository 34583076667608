<template>
  <base-section-alternating id="alternating-features">
    <base-section-heading-alternating title="FirstVault Features" class="px-2">
      Secure your digital life using FirstVault: Securely store and access
      everything you need, and leave the security concerns to us.
    </base-section-heading-alternating>

    <v-container fluid>
      <v-row v-if="$vuetify.breakpoint.mdAndUp">
        <template v-for="(feature, i) in features">
          <v-col
            v-if="
              i === 0 ||
              i === 1 ||
              i === 4 ||
              i === 5 ||
              i === 8 ||
              i === 9 ||
              i === 12 ||
              i === 13
            "
            id="color"
            class="my-4 py-4"
            :key="i"
            cols="12"
            sm="6"
          >
            <base-avatar-card v-bind="feature" align="justify" horizontal />
          </v-col>
          <v-col v-else class="ma-0 pa-0" :key="i" cols="12" sm="6">
            <base-avatar-card v-bind="feature" align="justify" horizontal />
          </v-col>
        </template>
      </v-row>
      <v-row v-else>
        <template v-for="(feature, j) in featuresMini">
          <v-col
            v-if="j === 0 || j === 2 || j === 4 || j === 6"
            id="color"
            :key="j"
            cols="12"
            sm="12"
          >
            <base-avatar-card v-bind="feature" align="justify" horizontal />
          </v-col>
          <v-col v-else :key="j" cols="12" sm="12">
            <base-avatar-card v-bind="feature" align="justify" horizontal />
          </v-col>
        </template>
      </v-row>
    </v-container>
  </base-section-alternating>
</template>

<script>
export default {
  name: "AlternatingFeatures",
  computed: {
    featuresMini() {
      var tempArray = this.features;
      var indexes = [1, 2, 5, 6, 9, 10, 13];
      tempArray = tempArray.filter(function (value, index) {
        return indexes.indexOf(index) == -1;
      });
      return tempArray;
    },
  },

  data: () => ({
    features: [
      {
        title: "TAKE COMPLETE CONTROL OF YOUR DIGITAL LIFE",
        text: "FirstVault provides a seamless solution to safely secure all your personal data. FirstVault completely removes the need to remember or worry about sensitive data such as passwords, images, files, etc. Get on board and secure your digital life with our free trial today.",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "SINGLE-KEY ACCESS",
        text: "Access all your sensitive data with a single key: FirstVault encrypts all your sensitive data, which can be accessed only by you, by using a 'Master security key' that only you know. Even FirstVault can't access your sensitve data.",
      },
      {
        title: "FIRSTVAULT DOES NOT WANT YOUR DATA!",
        text: "FirstVault with its zero-knowledge architecture, can't see any information you store on the platform. We firmly believe in data privacy and don't make money by selling your data. Data security is what we do and we are the best at it!",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "SPECIFICALLY BUILT FOR DATA SECURITY",
        text: "Stay protected against ransomware attacks and create strong passwords for apps, services and websites used by you. Protect your sensitive data including images, videos, files and documents in an extremely secure and encrypted digital vault.",
      },
      {
        title: "MAKES YOUR ONLINE EXPERIENCE EASY",
        text: "FirstVault can fill in all your passwords and payment details whenever you require them. FirstVault simplifies storing and organizing custom information, such as a Driver's License or Passport Number, with your existing records.",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "EASY ACCESS, ANYWHERE",
        text: "Save passwords and logins as you browse. FirstVault lets you store an unlimited number of passwords and access them on any device from anywhere. Access all your sensitive data on your desktop or on your mobile!",
      },
      {
        title: "SOMETHING FOR EVERYONE",
        text: "FirstVault provides a complete product suite to fulfil all your data security needs. FirstVault has two product offerings to cater for your needs well:  1. FirstVault Basic and 2. FirstVault Premium",
      },
      {
        title: "",
        text: "",
      },
    ],
  }),
};
</script>
<style scoped>
#color {
  background-color: rgb(237, 247, 254);
  font-size: 1.9rem;
}
.v-col {
  font-size: 50px;
}
</style>
